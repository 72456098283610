import logo from 'assets/images/logo.svg';
import SnsButton from '../SnsButton/SnsButton';
import twitter from 'assets/images/twitter.svg';
import discord from 'assets/images/discord.svg';
import styles from './Header.module.css';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Header() {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Fidelion | home';
        break;
      case '/gallery':
        document.title = 'Fidelion | gallery';
    }
  }, [location.pathname]);

  return (
    <header
      className={`${styles.header} ${location.pathname === '/gallery' && styles.gallery}`}
    >
      <Link to={'/'}>
        <img className={styles.logo} src={logo} alt='fidelion-logo' />
      </Link>
      <div className={styles.buttonContainer}>
        <Link className={styles.galleryButton} to={'/gallery'}>
          Gallery
        </Link>
        <SnsButton
          icon={twitter}
          alt='twitter'
          url={'https://twitter.com/FidelionNFT'}
        />
        <SnsButton
          icon={discord}
          alt='discord'
          url={'https://discord.com/invite/officialfidelion'}
        />
      </div>
    </header>
  );
}

export default Header;
